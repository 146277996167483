import React from 'react';
import './style.css'
import { Link } from 'react-router-dom';
const Footer: React.FC = () => {
  return (
    <div className='section'>
      <footer className='footer-section'>
        <div className='footer-section-container'>
          <div className='footer-content'>
            <div>
              <span className='company-name'>Blue Pigeon</span>
            </div>
            <div className='link-container'>
              <div>
                <Link to='/contact' className='link'>Contact us</Link>
              </div>
              <div className='divider'></div>
              <div>
                <Link to='/terms-and-conditions' className='link'>Terms and conditions </Link>
              </div>
              <div className='divider'></div>
              <div>
                <Link to='/privacy-policy' className='link'>Privacy policy </Link>
              </div>
            </div>

            <div className='pattern'>
            <img src="/icons/footerPattern.svg" alt="pattern" />

            </div>
            <div className='social-links'>
              <Link to='https://www.facebook.com/'>
                <img src="/icons/facebook.svg" alt="facebook" />
              </Link>
              <Link to='https://www.instagram.com/'>
                <img src="/icons/instagram.svg" alt="instagram" />
              </Link>
            </div>
            <div className='all-right-reserved'>&copy;2023 Blue Pigeon. All right reserved</div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
