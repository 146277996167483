import React from 'react';
import Navbar from './Navbar';
import './style.css'

interface LayoutProps{
    children:React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className='main-container'>{children}</div>
    </>
  );
}

export default Layout;
