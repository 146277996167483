import React from 'react';
import Feature from '../../components/Feature';
import HeroSection from '../../components/HeroSection';
import { FeatureData, HeroSectionData } from '../../interfaces';
import ReactFullpage from '@fullpage/react-fullpage';
import Footer from '../../components/Layout/Footer';

const heroSectionData: HeroSectionData = {
  imageUrl: '/images/heroSection.png',
  title: 'Quickest way to talk with your coworkers.',
  description: 'Made for Teams. Designed for Productivity.'
}

const featureSectionData: FeatureData[] = [
  {
    imageUrl: '/images/section1.png',
    title: 'Communicate in-short with your coworkers.',
    description: 'Why spend your valuable time on calls, video conferences, and on texting messages, when you can communicate quickly with your coworkers in a few seconds.',
    imagePosition: 'left',
  },
  {
    imageUrl: '/images/section2.png',
    title: 'Communicate quickly without spending too much time.',
    description: 'Communicate quickly and instantly by sending short voice notes, instead of waiting for your coworkers to be available to be on the call.',
    imagePosition: 'right',
  },
  {
    imageUrl: '/images/section3.png',
    title: "Focus on what's important.",
    description: 'Your time is extremely valuable and is the most critical asset. Communicate with your team instantly and in-short, and spend your time on getting things done.',
    imagePosition: "left",
  }
]

const Home: React.FC = () => {


  return <ReactFullpage
    scrollingSpeed={1000}
    credits={{ enabled: false, label: '', position: 'right' }}
    navigation={true}
    navigationPosition='right'
    showActiveTooltip={true}
    licenseKey={"YOUR_LICENSE_KEY"}
    verticalCentered={true}
    keyboardScrolling={true}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <HeroSection data={heroSectionData}  fullpageApi={fullpageApi}/>
          {featureSectionData.map((item, index) => {
            return (
              <Feature data={item} key={index} />
            );
          })}
          <Footer />
        </ReactFullpage.Wrapper>
      );
    }} />;
}

export default Home;
