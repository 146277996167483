import React from 'react';
import { FeatureData } from '../../interfaces';
import './style.css'

interface FeatureProps {
  data: FeatureData
}

const Feature: React.FC<FeatureProps> = ({ data }) => {
  const {
    imageUrl,
    title,
    description,
    imagePosition = 'left', // Default position is 'left'
  } = data
  return (
    <div className='section'>
      <div className={` feature-container  ${imagePosition}`}>
        <div className="image-block">
          <img src={imageUrl} alt="section img" />
        </div>
        <div className="content-block">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Feature;
