import React from 'react'
import './style.css'
import { HeroSectionData } from '../../interfaces'
import { Link } from 'react-router-dom'

interface HeroSectionProps {
  data: HeroSectionData
  fullpageApi:any
}

const HeroSection: React.FC<HeroSectionProps> = ({ data,fullpageApi }) => {
  const {
    imageUrl,
    title,
    description,
  } = data
  const handleButtonClick = () => {
     fullpageApi.moveSectionDown()
  };
  return (
    <div className='section' id='section-1'>
      <div className='hero-section'>
        <div className='hero-section-container'>
          <div className="content-block">
            <h2>{title}</h2>
            <p className='des'>{description}</p>
            <div className="button-block">
              <Link to='https://www.apple.com/in/app-store/'>
                <img src="/icons/appStore.svg" alt="" />
              </Link>
              <Link to='https://play.google.com/store/games?hl=en&gl=US&pli=1'>
                <img src="/icons/googlePlay.svg" alt="" />
              </Link>
            </div>
          </div>
          <div className="image-block">
            <img src={imageUrl} alt="Half" />
          </div>


        </div>
        <div className="scroll-down">
          <img src="/icons/downArrow.svg" alt="Scroll Down" onClick={handleButtonClick} />
        </div>
      </div>
    </div>
  )
}

export default HeroSection