import React from 'react'
import './style.css'

const PageNotFound:React.FC = () => {
  return (
    <div className='page-not-found-container'>
      <img src="/images/pageNotFound.jpg" alt="page not found" />
    </div>
  )
}

export default PageNotFound