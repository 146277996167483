import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div>
      <h2>Privacy Policy Page</h2>
    </div>
  );
}

export default PrivacyPolicy;
