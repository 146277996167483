import React from 'react'

const About: React.FC = () => {
    return (
        <div className='page-container'>
            <h2>About Us</h2>
        </div>
    )
}

export default About